import "react-date-range/dist/styles.css";
import "../../assets/vendor/date-range/date-range-picker-theme.css";

import React, { useState, useContext, useEffect } from "react";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import staticRanges from "../../util/static-range-generator-for-date-picker";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  PopoverBody,
  Row,
  UncontrolledPopover
} from "reactstrap";
import api from "../../api";
import TimesheetSummary from "./TogglTimesheetSummaryTable";
import MultiSelect from "../MultiSelect";
import { DateRangePicker } from "react-date-range";
import { useQuery } from "react-query";
import AuthContext from "../../contexts/AuthContext";

const ToggleTimeSheetEntries = ({
  onSummaryChange = () => {},
  initialFilters = {}
}) => {
  const [selectedProjects, setSelectedProjects] = useState(
    initialFilters.selectedProjects || []
  );
  const [selectedClients, setSelectedClients] = useState(
    initialFilters.selectedClients || []
  );
  const [activeWorkspace, setActiveWorkspace] = useState(null);
  const { apiReady } = useContext(AuthContext);
  const [datePickerSelectedDateRanges, setDatePickerSelectedDateRanges] =
    useState(
      initialFilters.datePickerSelectedDateRanges || [
        {
          startDate: addDays(new Date(), -30),
          endDate: new Date(),
          key: "selection"
        }
      ]
    );
  const { workspaces, workspaceProjects, clients, timesheetSummary } =
    useApiDataInitializer();
  useEffect(() => {
    if (timesheetSummary) {
      onSummaryChange({
        timesheetSummary,
        filters: {
          workspaceId: activeWorkspace && activeWorkspace.id,
          selectedProjects,
          selectedClients,
          datePickerSelectedDateRanges
        }
      });
    }
  }, [timesheetSummary]);
  return (
    <div className={"container"}>
      <Card>
        <CardBody>
          <Form>
            <Row>
              {workspaces.length > 2 && (
                <FormGroup className={"mb-3 focus col-sm-4"}>
                  <label htmlFor="workspaces">
                    {" "}
                    <small>Workspace</small>
                  </label>
                  <MultiSelect
                    name={"workspaces"}
                    options={{
                      placeholder: "Workspaces"
                    }}
                    data={
                      workspaces
                        ? workspaces.map((w) => ({
                            value: w.id,
                            label: w.name
                          }))
                        : []
                    }
                  />
                </FormGroup>
              )}
              <FormGroup className={"mb-3 focus col-sm-4"}>
                <label htmlFor="projects">
                  <small>Show entries for these projects</small>
                </label>
                <MultiSelect
                  className="pointer"
                  isMulti
                  closeMenuOnSelect={false}
                  placeholder={"Select projects"}
                  onChange={setSelectedProjects}
                  defaultValue={initialFilters.selectedProjects}
                  options={getProjectOptions()}
                />
              </FormGroup>

              <FormGroup className={"mb-3 focus col-sm-4"}>
                <label htmlFor="clients">
                  <small>Show entries for these clients</small>
                </label>
                <MultiSelect
                  className="pointer"
                  isMulti
                  placeholder={"Select clients"}
                  closeMenuOnSelect={false}
                  defaultValue={initialFilters.selectedClients}
                  onChange={setSelectedClients}
                  options={getClientOptions()}
                />
              </FormGroup>
              <FormGroup className={"mb-3 focus col-sm-4"}>
                <label htmlFor="date-picker-tooltip">
                  <small>Show entries between these dates</small>
                </label>
                <Input
                  id="date-picker-tooltip"
                  type="button"
                  className={"form-control"}
                  value={`${format(
                    datePickerSelectedDateRanges[0].startDate,
                    "dd MMM yyyy"
                  )} - ${format(
                    datePickerSelectedDateRanges[0].endDate,
                    "dd MMM yyyy"
                  )}`}
                />
                <UncontrolledPopover
                  placement="top"
                  target="date-picker-tooltip"
                  className="popover-default unbounded-width"
                  trigger="legacy"
                  hideArrow={true}
                  fade={false}
                >
                  <PopoverBody>
                    <DateRangePicker
                      onChange={(item) => {
                        setDatePickerSelectedDateRanges([item.selection]);
                      }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={datePickerSelectedDateRanges}
                      direction="horizontal"
                      fixedHeight={true}
                      inputRanges={[]}
                      weekStartsOn={1}
                      staticRanges={staticRanges}
                    />
                  </PopoverBody>
                </UncontrolledPopover>
              </FormGroup>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <TimesheetSummary summary={timesheetSummary} />
        </CardBody>
      </Card>
    </div>
  );

  function getClientOptions() {
    return clients
      .map((client) => ({
        value: client.id,
        label: `${client.name || "No Name"}`
      }))
      .concat({
        value: 0,
        label: "No Client"
      });
  }
  function getProjectOptions() {
    return workspaceProjects
      .map((w) => ({
        value: w.id,
        label: `${w.name} - ${w.client?.name || "No client"}`
      }))
      .concat({
        value: 0,
        label: "No project"
      });
  }
  function useApiDataInitializer() {
    const { data: workspaces = [] } = useQuery(
      "workspaces",
      api.getWorkspaces,
      {
        enabled: apiReady,
        onSuccess: (workspaces) => {
          setActiveWorkspace(workspaces[0]);
        }
      }
    );
    const { data: workspaceProjects = [] } = useQuery(
      ["workspaceProjects", activeWorkspace && activeWorkspace.id],
      ({ queryKey }) => {
        const workspaceId = queryKey[1];
        return api.getWorkspaceProjects(workspaceId);
      },
      {
        enabled: apiReady && !!activeWorkspace
      }
    );

    const { data: clients = [] } = useQuery("clients", api.getClients, {
      enabled: apiReady
    });
    const { data: timesheetSummary } = useQuery(
      [
        "timesheetSummary",
        {
          workspaceId: activeWorkspace && activeWorkspace.id,
          selectedProjects: selectedProjects,
          selectedClients: selectedClients,
          selectedDateRange: datePickerSelectedDateRanges[0]
        }
      ],
      ({ queryKey }) => {
        // eslint-disable-next-line no-unused-vars
        const [
          _,
          { workspaceId, selectedProjects, selectedClients, selectedDateRange }
        ] = queryKey;
        return api.getTimesheetSummary(workspaceId, {
          projectIds: selectedProjects.map((p) => p.value),
          clientIds: selectedClients.map((c) => c.value),
          dateRange: selectedDateRange
        });
      },
      {
        enabled: apiReady && !!activeWorkspace
      }
    );
    return {
      workspaces,
      workspaceProjects,
      clients,
      timesheetSummary
    };
  }
};

export default ToggleTimeSheetEntries;

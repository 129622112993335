import { Button, Container, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import xeroIcon from "../assets/img/xero.svg";
import differenceInSeconds from "date-fns/differenceInSeconds";
import api from "../api";

const authFLowTimeoutDuration = 60;

const states = {
  IDLE: "IDLE",
  WAITING_FOR_AUTH: "WAITING_FOR_AUTH",
  SUCCEEDED: "SUCCEEDED",
  FAILED: "FAILED",
  EXPIRED: "EXPIRED"
};
const XeroOAuth = ({ done, cancel }) => {
  const [oauthState, setOauthState] = useState("IDLE");
  const [remainingAuthTime, setRemainingAuthTime] = useState(
    authFLowTimeoutDuration
  );
  const [authorizeUrl, setAuthorizeUrl] = useState();
  const [authPopup, setAuthPopup] = useState(null);
  let authFlowTimeout;
  let authFlowCountdownInterval;

  useEffect(() => {
    getAuthorizeUrl();
  }, []);

  async function getAuthorizeUrl() {
    try {
      const getUrlResponse = await api.xero.getOauthAuthorizeUrl();
      setAuthorizeUrl(getUrlResponse.authorizeUrl);
    } catch (e) {
      console.error(e);
    }
  }

  const Idle = () => (
    <div>
      <p className={"text py-3 text-center"}>
        Let's connect your Xero account so that we can start generating invoices
        from your timesheets.
      </p>
      <div className="d-flex justify-content-center">
        <Button
          color="primary"
          disabled={!authorizeUrl}
          className={"btn btn-icon btn-3 btn-primary"}
          onClick={startAuthFlow}
        >
          {authorizeUrl && (
            <>
              <span className="btn-inner--icon">
                <i className="ni ni-check-bold" />
              </span>
              <span className="btn-inner--text">Let's go!</span>
            </>
          )}
          {!authorizeUrl && (
            <>
              <Spinner type="grow" size="sm" as={"span"}></Spinner>&nbsp; Just a
              sec...
            </>
          )}
        </Button>
      </div>
    </div>
  );

  const WaitingForAuth = () => (
    <div>
      <p className={"text pt-3 text-center"}>
        Please complete the authentication flow in the popup window before the
        timer reaches 0
      </p>
      <div
        className={
          "d-flex justify-content-center text-center display-1 border-0 pb-5 pt-3 text-primary"
        }
      >
        <div
          className={
            "d-flex align-items-center justify-content-center oauth-status-circle"
          }
        >
          {remainingAuthTime}
        </div>
      </div>
      <div
        className="d-flex justify-content-center"
        onClick={() => {
          authPopup && authPopup.close();
          cancel();
        }}
      >
        <Button color="danger" type="button" className={"btn-icon btn-3"}>
          <span className="btn-inner--text">Cancel</span>
        </Button>
      </div>
    </div>
  );

  const Expired = () => (
    <div>
      <p className={"text py-3 text-center"}>
        Authentication was not completed in time.
      </p>
      <div
        className={
          "d-flex justify-content-center text-center display-1 border-0 pb-5 pt-3 text-warning"
        }
      >
        <div
          className={
            "d-flex align-items-center justify-content-center oauth-status-circle expired"
          }
        >
          {remainingAuthTime}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          color="secondary"
          type="button"
          className={"btn-icon btn-3"}
          onClick={() => {
            authPopup && authPopup.close();
            cancel();
          }}
        >
          <span className="btn-inner--text">Cancel</span>
        </Button>
        <Button
          color="primary"
          type="button"
          className={"btn-icon btn-3"}
          onClick={startAuthFlow}
        >
          <span className="btn-inner--text">Try again</span>
        </Button>
      </div>
    </div>
  );

  const Succeeded = () => (
    <div>
      <p className={"text pt-3 text-center"}>
        Your Xero account was linked successfully
      </p>
      <div
        className={
          "d-flex justify-content-center text-center display-1 border-0 pb-5 pt-3 text-success"
        }
      >
        <div
          className={
            "d-flex align-items-center justify-content-center oauth-status-circle success"
          }
        >
          <i className="ni ni-check-bold" />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          color="primary"
          type="button"
          className={"btn-icon btn-3"}
          onClick={done}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-like-2" />
          </span>
          <span className="btn-inner--text">Ok</span>
        </Button>
      </div>
    </div>
  );

  return (
    <Container>
      <div className={"d-flex justify-content-center"}>
        <img src={xeroIcon} width={45} />
      </div>
      <h1 className={"text-center pt-2"}>Link Xero Account</h1>
      {oauthState === states.IDLE && <Idle />}
      {oauthState === states.WAITING_FOR_AUTH && <WaitingForAuth />}
      {oauthState === states.SUCCEEDED && <Succeeded />}
      {oauthState === states.EXPIRED && <Expired />}
    </Container>
  );

  function startAuthFlow() {
    const authPopup = window.open(
      authorizeUrl,
      "blank",
      `toolbar=no, menubar=no, width=600, height=700, top=100, left=${
        document.documentElement.clientWidth - 700
      }`
    );
    setAuthPopup(authPopup);
    setOauthState(states.WAITING_FOR_AUTH);
    const authFlowStartTime = new Date();
    setRemainingAuthTime(authFLowTimeoutDuration);
    authFlowCountdownInterval = setInterval(() => {
      const elapsedTime = differenceInSeconds(new Date(), authFlowStartTime);
      setRemainingAuthTime(Math.max(0, authFLowTimeoutDuration - elapsedTime));
    }, 1000);

    const checkAuthedInterval = setInterval(async () => {
      try {
        const appResponse = await api.getUserApp("xero");
        if (appResponse.isConnected) {
          setOauthState(states.SUCCEEDED);
          clearInterval(checkAuthedInterval);
          clearInterval(authFlowCountdownInterval);
        }
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    }, 5000);

    setTimeout(() => {
      clearInterval(authFlowCountdownInterval);
      clearInterval(checkAuthedInterval);
      setOauthState(states.EXPIRED);
    }, authFLowTimeoutDuration * 1000);
  }
};

export default XeroOAuth;

import { makeCall } from "./core";
import * as constants from "../constants.js";

async function createInvoice(invoice) {
  try {
    return makeCall("/xero/invoices", invoice, { method: "post" });
  } catch (e) {
    throw e.message;
  }
}

async function getContactsSummary() {
  try {
    return await makeCall("/xero/contacts-summary", null, { method: "get" });
  } catch (e) {
    if (e.response?.data?.data?.code === constants.XERO_ACCOUNT_NOT_CONNECTED) {
      const authorizeResponse = await makeCall("/xero/authorize-url");
      throw {
        code: constants.XERO_ACCOUNT_NOT_CONNECTED,
        authorizeUrl: authorizeResponse.authorizeUrl
      };
    }
    throw  e.message;
  }
}

async function getItems() {
  try {
    return await makeCall("/xero/items", null, { method: "get" });
  } catch (e) {
    throw e.message;
  }
}

async function getInvoice(id) {
  try {
    return makeCall("/xero/invoices/" + id, null, { method: "get" });
  } catch (e) {
    throw e.message;
  }
}

async function getOauthAuthorizeUrl() {
  try{
    return await makeCall("/xero/authorize-url");
  }catch(e){
    throw e.message
  }

}

async function getConnectionInfoForUser() {
  try{
    return await makeCall("/xero/connection-info");
  }catch(e){
    throw e.message
  }

}

const xero = {
  createInvoice,
  getContactsSummary,
  getItems,
  getInvoice,
  getOauthAuthorizeUrl,
  getConnectionInfoForUser
};

export default xero;

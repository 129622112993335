import AuthContext from "../../contexts/AuthContext";
import { useLocalStorage, writeStorage } from "@rehooks/local-storage";
import api from "../../api";

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

const AuthContextProvider = (props) => {
  const [authData = null] = useLocalStorage("authData");
  const [apiReady, setApiReady] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const isUnsecuredRoute =
    location.pathname === "/register" || location.pathname === "/login";
  useEffect(() => {
    init();
  });

  return (
    <AuthContext.Provider value={{ authData, setAuthData, apiReady }}>
      {(authData || isUnsecuredRoute) && props.children}
    </AuthContext.Provider>
  );

  function setAuthData(authData) {
    writeStorage("authData", authData);
    api.setAuthToken(authData.token);
  }

  function init() {
    if (!authData) {
      if (!isUnsecuredRoute) history.push("/login");
    } else {
      api.setAuthToken(authData.token);
    }
    setApiReady(true);
  }
};

export default AuthContextProvider;

import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import AuthContext from "../contexts/AuthContext";
import { useHistory } from "react-router";
import googleIcon from "../assets/img/icons/common/google.svg";
import githubIcon from "../assets/img/icons/common/github.svg";

import {
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Alert
} from "reactstrap";
import api from "../api";
import { Link } from "react-router-dom";
const redirectTo = "/invoices/new";

function LoginPage() {
  const { setAuthData } = useContext(AuthContext);
  const [error, setError] = useState("");
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    setError("");
    const { email, password } = data;
    try {
      const authData = await api.login(email, password);
      await setAuthData(authData);
      history.push(redirectTo);
    } catch (e) {
      setError(e.message);
    }
  };

  React.useEffect(() => {
    document.body.classList.add("register-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  }, []);
  return (
    <>
      <div className="wrapper">
        <div className="page-header bg-default">
          <Container>
            <div className="form-container sign-up-container">
              <div className="bg-transparent pb-5">
                <div className=" text-center mt-2 mb-3">
                  <span>Sign in with</span>
                </div>
                <div className="btn-wrapper text-center">
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img alt="..." src={githubIcon} />
                    </span>
                    <span className="btn-inner--text">Github</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img alt="..." src={googleIcon} />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                </div>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className=" text-center mt-2 mb-3">
                  <span>Or sign in with email and password</span>
                </div>
                {error && <Alert color={"danger"}>{error}</Alert>}
                <FormGroup className={"mb-3 focus"}>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <input
                      className={"form-control"}
                      placeholder="email"
                      type="email"
                      {...register("email", {
                        required: "email is required",
                        pattern: /^\S+@\S+$/i
                      })}
                    />
                  </InputGroup>
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <div className={"text-left"}>
                        <small className={"text-danger"}>{message}</small>
                      </div>
                    )}
                  />
                </FormGroup>
                <FormGroup className="focus">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                      className={"form-control"}
                      placeholder="Password"
                      type="password"
                      {...register("password", {
                        required: "password is required",
                        minLength: {
                          value: 6,
                          message: "must have 6 or more characters"
                        }
                      })}
                    />
                  </InputGroup>
                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => (
                      <div className={"text-left"}>
                        <small className={"text-danger"}>{message}</small>
                      </div>
                    )}
                  />
                  <div className={"mt-2 text-right"}>
                    <Link className="text-muted" to={"/register"}>
                      <small>Forgot password?</small>
                    </Link>
                  </div>
                </FormGroup>
                <Button color="primary">Sign in</Button>
                <div className="mt-3">
                  <div className={"mt-2 text-right"}>
                    <Link className="text-muted" to={"/register"}>
                      <small>Create new account</small>
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LoginPage;

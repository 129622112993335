import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  Row,
  Label
} from "reactstrap";
import MultiSelect from "./MultiSelect";
import { useEffect, useState } from "react";
import ToggleTimeSheetEntries from "./Toggl/TogglTimesheets";

const InvoiceItem = ({ products, onChange = () => {} }) => {
  const [unitPrice, setUnitPrice] = useState(products[0].unitPrice);
  const [itemQuantity, setItemQuantity] = useState(1);
  const [showTimesheetSelect, setShowTimesheetSelect] = useState(false);
  const [timesheetFilters, setTimesheetFilters] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({
    value: products[0].id,
    label: products[0].name,
    unitPrice: products[0].unitPrice
  });

  useEffect(() => {
    onChange({
      unitPrice: Number(unitPrice || 0),
      itemQuantity: Number(itemQuantity) || 0,
      selectedProduct
    });
  }, [unitPrice, itemQuantity, selectedProduct]);

  return (
    <FormGroup>
      <Row>
        <Col>
          <small>
            <Label htmlFor="product">Product/service</Label>
          </small>
          <MultiSelect
            name={"product"}
            placeholder={"select product/service"}
            defaultValue={{
              value: products[0].id,
              label: products[0].name,
              unitPrice: products[0].unitPrice
            }}
            options={products.map((b) => ({
              value: b.id,
              label: b.name,
              unitPrice: b.unitPrice
            }))}
            onChange={(selected) => {
              setUnitPrice(selected.unitPrice);
              setSelectedProduct(selected);
            }}
          />
        </Col>
        <Col>
          <small>
            <Label htmlFor="quantity" size={"small"}>
              Quantity/hours
            </Label>
          </small>
          <InputGroup className="input-group">
            <Input
              placeholder="Quantity"
              type={"number"}
              value={itemQuantity}
              onChange={(e) => {
                setItemQuantity(e.target.valueAsNumber || "");
              }}
            />
            <Modal
              className="modal-dialog-centered timesheets"
              toggle={toggleShowTimesheetSelect}
              isOpen={showTimesheetSelect}
              fade={false}
            >
              <ToggleTimeSheetEntries
                onSummaryChange={(results) => {
                  setTimesheetFilters(results.filters);
                  setItemQuantity(
                    (
                      (results.timesheetSummary.total_grand || 0) / 3600000
                    ).toFixed(2)
                  );
                }}
                initialFilters={timesheetFilters}
              />
              <div className={"py-2"}>
                <Button
                  color={"primary"}
                  className={"float-right"}
                  id={"generate-invoice-options"}
                  onClick={toggleShowTimesheetSelect}
                >
                  Save
                  <span className="caret" />
                </Button>
              </div>
            </Modal>
            <InputGroupAddon addonType={"append"}>
              <div>
                {/*<Button*/}
                {/*  type={"button"}*/}
                {/*  color={"transparent"}*/}
                {/*  className={"clear-z-index"}*/}
                {/*  id={"timesheet-selector"}*/}
                {/*  onClick={toggleShowTimesheetSelect}*/}
                {/*>*/}
                <img
                  src={"/toggl.png"}
                  alt={"lol"}
                  style={{
                    width: "46px",
                    minHeight: "46px",
                    borderRadius: "0 5px 5px 0",
                    cursor: "pointer",
                    padding: "10px",
                    background: "#2C1338"
                  }}
                  onClick={toggleShowTimesheetSelect}
                />
                {/*</Button>*/}
              </div>
            </InputGroupAddon>
          </InputGroup>
        </Col>{" "}
        <Col>
          <small>
            <Label htmlFor="unit-price">Unit price</Label>
          </small>
          <Input
            name={"unit-price"}
            value={unitPrice}
            placeholder="unit price"
            type="number"
            onChange={(e) => setUnitPrice(e.target.valueAsNumber || "")}
          />
        </Col>
        <Col>
          <small>
            <Label htmlFor="unit-price">Total ($)</Label>
          </small>
          <Input
            value={unitPrice * itemQuantity}
            placeholder="total"
            type="number"
            disabled
          />
        </Col>
      </Row>
    </FormGroup>
  );

  function toggleShowTimesheetSelect() {
    setShowTimesheetSelect(!showTimesheetSelect);
  }
};

export default InvoiceItem;

import Select from "react-select";
const multiSelect = (props) => {
  const defaultStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
      fontSize: "0.875rem",
      color: state.isSelected ? "white" : "#525f7f",
      backgroundColor: state.isSelected ? "var(--blue)" : "transparent",
      minHeight: "2rem",
      "&:hover": {
        backgroundColor: state.isSelected ? "var(--blue)" : "var(--lighter)"
      },
      zIndex: 3
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      height: "calc(1.5em + 1.25rem + 5px)", // From src/assets/scss/core/forms/_form.scss:20,
      fontSize: "0.875rem",
      color: "#525f7f",
      border: "1px solid var(--lighter);",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#dee2e6"
      },
      "&:focus": {
        borderColor: "#5e72e4"
      },
      width: "100%"
    }),
    singleValue: (styles) => ({
      color: "#525f7f"
    })
  };
  const finalStyles = { ...defaultStyles, ...(props.styles || {}) };
  const finalProps = { ...props, styles: finalStyles };

  return <Select {...finalProps} />;
};

export default multiSelect;

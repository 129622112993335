import TogglTimesheetEntries from "../components/Toggl/TogglTimesheets";

const Timesheets = (props) => {
  return (
    <div className="App">
      <TogglTimesheetEntries onSummaryChange={(summary) => {}} />
    </div>
  );
};

export default Timesheets;

import axios from "axios";
import format from "date-fns/format";
import APIError from "./api-error";
import { setAuthToken, makeCall } from "./core";
import xero from "./xero";

function get(path, data, options) {
  return makeCall(path, data, { method: "get", ...options });
}

function post(path, data, options) {
  return makeCall(path, data, { method: "post", ...options });
}

const api = {
  xero,
  getWorkspaceProjects,
  getWorkspaces,
  getTimesheetSummary,
  getClients,
  getLoggedInUser,
  login,
  register,
  setAuthToken,
  getUserApps,
  getUserApp,
  getWaveAuthUrl,
  getWaveCustomers,
  getWaveProducts,
  createWaveInvoice
};

async function getWorkspaceProjects(workspaceId) {
  return await get(`/toggl/workspaces/${workspaceId}/projects`);
}

async function getWorkspaces() {
  return await get(`/toggl/workspaces`);
}

async function getClients() {
  return await get(`/toggl/clients`);
}

async function getTimesheetSummary(
  workspaceId,
  { projectIds = [], clientIds = [], dateRange = {} }
) {
  const startDate =
    dateRange.startDate && format(dateRange.startDate, "yyyy-MM-dd");
  const endDate =
    dateRange.startDate && format(dateRange.endDate, "yyyy-MM-dd");
  return await get(`/toggl/timesheet-summary`, null, {
    params: {
      workspaceId,
      projectIds: projectIds.join(","),
      clientIds: clientIds.join(","),
      startDate,
      endDate
    }
  });
}

async function getLoggedInUser() {
  const user = await get("/me");
  return user;
}

async function login(email, password) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_BASE + `/login`,
      {
        email,
        password
      }
    );
    return response.data;
  } catch (e) {
    throw new APIError(e.response.data?.message, e);
  }
}

async function register(email, password) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_BASE + `/register`,
      {
        email,
        password
      }
    );
    return response.data;
  } catch (e) {
    throw new APIError(e.response.data?.message, e);
  }
}

async function getUserApps() {
  return await get("/me/apps");
}

async function getUserApp(appName) {
  return await get("/me/apps/" + appName);
}

async function getWaveAuthUrl() {
  return await get("/wave/authorize-url");
}

async function getWaveCustomers(businessId) {
  return await get("/wave/customers", null, {});
}

async function getWaveProducts() {
  return await get("/wave/products", null, {});
}

async function createWaveInvoice(invoiceData) {
  return await post("/invoices/wave", invoiceData, {});
}

export default api;

import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  PopoverBody,
  UncontrolledAlert,
  UncontrolledPopover
} from "reactstrap";
import { useQuery } from "react-query";
import api from "../api";
import React, { useContext, useState } from "react";
import MultiSelect from "../components/MultiSelect";
import AuthContext from "../contexts/AuthContext";
import { Calendar } from "react-date-range";
import endOfMonth from "date-fns/endOfMonth";
import endOfDay from "date-fns/endOfDay";
import format from "date-fns/format";
import uniqueId from "lodash/uniqueId";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import isDate from "date-fns/isDate";
import { formatAsMoney } from "../components/Money";
import { useHistory } from "react-router-dom";
import xeroIcon from "../assets/img/xero.svg";
import togglIcon from "../assets/img/icon-pink.svg";
import XeroOAuth from "../components/XeroOAuth";
import ToggleTimeSheetEntries from "../components/Toggl/TogglTimesheets";

const NewXeroInvoice = () => {
  const { apiReady } = useContext(AuthContext);
  const history = useHistory();
  const [oAuthing, setOAuthing] = useState(false);
  const [importingFromToggl, setImportingFromToggl] = useState(false);

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [pageError, setPageError] = useState("");
  const [getContactsError, setGetContactsError] = useState();
  const [isXeroAuthenticated, setIsXeroAuthenticated] = useState(null);

  const { error } = useQuery(
    "xeroApp",
    async () => {
      return (await api.getUserApp("xero")).isConnected;
    },
    {
      enabled: apiReady,
      onSuccess: setIsXeroAuthenticated
    }
  );

  const { data: customers = [] } = useQuery(
    ["xeroContacts", isXeroAuthenticated],
    async () => {
      return await api.xero.getContactsSummary();
    },
    {
      onError(e) {
        setGetContactsError(e);
      },
      enabled: !!isXeroAuthenticated,
      retry: false
    }
  );

  const { data: connectionInfo = {}, error: getConnectionInfoError } = useQuery(
    ["xeroConnectionInfo", isXeroAuthenticated],
    async () => {
      return await api.xero.getConnectionInfoForUser();
    },
    {
      enabled: !!isXeroAuthenticated,
      retry: false
    }
  );

  const { data: sellableItems = [], error: getItemsError } = useQuery(
    ["sellableItems", isXeroAuthenticated],
    async () => {
      return await api.xero.getItems();
    },
    {
      enabled: !!isXeroAuthenticated
    }
  );

  const today = new Date();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    trigger
  } = useForm({
    defaultValues: {
      Date: today,
      DueDate: endOfMonth(today),
      LineItems: [
        {
          id: uniqueId(),
          Quantity: 1
        }
      ]
    }
  });

  const dueDate = watch("DueDate");
  const issueDate = watch("Date");
  const LineItems = watch("LineItems");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "LineItems"
  });

  return (
    <Container>
      <br />
      <h1>New invoice</h1>
      <Card>
        <CardBody>
          <Container>
            {(getContactsError || getItemsError) && (
              <UncontrolledAlert color={"danger"}>
                {getContactsError &&
                  "Error getting the list of clients: " + getContactsError}
                <br />
                {getItemsError &&
                  "Error getting the list of items: " + getItemsError}
              </UncontrolledAlert>
            )}
          </Container>

          {isXeroAuthenticated && (
            <>
              <Container className={"d-flex justify-content-end"}>
                <div className={"d-flex align-items-center p-3"}>
                  <div className="avatar rounded-circle mr-2 ">
                    <img alt="Xero Logo" src={xeroIcon} width={100} />
                  </div>
                  &nbsp;
                  {connectionInfo.tenantName && (
                    <span className={"d-flex align-items-center text-lg text"}>
                      {connectionInfo.tenantName}
                    </span>
                  )}
                </div>
              </Container>
              <Form
                onSubmit={handleSubmit(async (data) => {
                  if (!data.InvoiceNumber) delete data.InvoiceNumber;
                  try {
                    const invoice = await api.xero.createInvoice(data);
                    history.push(`/?createdXeroInvoice=${invoice.InvoiceID}`);
                  } catch (e) {
                    setPageError(e.message);
                  }
                })}
              >
                <FormGroup>
                  <div className="col-sm-4">
                    <label htmlFor="Contact.ContactID">
                      {" "}
                      <small>Contact</small>
                    </label>
                    <Controller
                      name={"Contact.ContactID"}
                      control={control}
                      rules={{ required: "Please select a contact" }}
                      render={({ field: { onChange } }) => (
                        <MultiSelect
                          placeholder={"Who is this invoice for"}
                          options={customers.map((c) => ({
                            value: c.ContactID,
                            label: getCustomerDisplayValue(c)
                          }))}
                          onChange={(selected) => onChange(selected.value)}
                        />
                      )}
                    />
                    {errors.Contact && (
                      <ErrorMessage
                        errors={errors}
                        name="Contact.ContactID"
                        render={({ message }) => (
                          <div className={"text-left"}>
                            <small className={"text-danger"}>{message}</small>
                          </div>
                        )}
                      />
                    )}
                  </div>
                </FormGroup>{" "}
                <FormGroup>
                  <div className="col-sm-4">
                    <label htmlFor="date-picker-tooltip">
                      <small>Due date</small>
                    </label>
                    <input
                      id="due-date-picker-tooltip"
                      type="button"
                      className={"form-control"}
                      value={`${
                        isDate(dueDate) ? format(dueDate, "dd MMM yyyy") : "lol"
                      } `}
                    />
                    <UncontrolledPopover
                      placement="top"
                      target="due-date-picker-tooltip"
                      className="popover-default unbounded-width"
                      trigger="legacy"
                      hideArrow={true}
                      fade={false}
                    >
                      <PopoverBody>
                        <Controller
                          name={"DueDate"}
                          defaultValue={new Date()}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <>
                              <Calendar
                                onChange={(selected) => {
                                  onChange(endOfDay(selected));
                                }}
                                showSelectionPreview={true}
                                months={1}
                                date={dueDate}
                                fixedHeight={true}
                                weekStartsOn={1}
                              />
                            </>
                          )}
                        />
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                </FormGroup>
                <div>
                  <div
                    className={"col-md-4 "}
                    onClick={() => setShowMoreOptions(!showMoreOptions)}
                  >
                    <small role="button" className={"text-primary"}>
                      <u>More options</u>
                    </small>
                  </div>
                </div>
                <div
                  className={`more-invoice-options ${
                    showMoreOptions ? "show" : ""
                  }`}
                >
                  <FormGroup className="col-sm-4">
                    <div>
                      <label htmlFor="issue-date-picker-tooltip">
                        <small>Issue date</small>
                      </label>
                      <input
                        id="issue-date-picker-tooltip"
                        type="button"
                        className={"form-control"}
                        value={`${format(issueDate, "dd MMM yyyy")}`}
                      />
                      <UncontrolledPopover
                        placement="top"
                        target="issue-date-picker-tooltip"
                        className="popover-default unbounded-width"
                        trigger="legacy"
                        hideArrow={true}
                        fade={false}
                      >
                        <PopoverBody>
                          ]
                          <Controller
                            name={"Date"}
                            control={control}
                            render={({ field: { onChange } }) => (
                              <Calendar
                                onChange={(selected) => {
                                  onChange(selected);
                                }}
                                showSelectionPreview={true}
                                date={issueDate}
                                fixedHeight={true}
                                weekStartsOn={1}
                              />
                            )}
                          />
                        </PopoverBody>
                      </UncontrolledPopover>
                    </div>
                  </FormGroup>
                  <FormGroup className="col-sm-4">
                    <div>
                      <label htmlFor="InvoiceNumber">
                        <small>Invoice number</small>
                      </label>
                      <input
                        className={"form-control"}
                        {...register("InvoiceNumber")}
                      />
                    </div>
                  </FormGroup>
                </div>
                <Container className={"mt-4"}>
                  <h3>Items</h3>
                  <div className="invoice-items">
                    <div>
                      <table className="table align-items-center">
                        <thead className="">
                          <tr>
                            <th scope="col" className={"col-3"}>
                              Item
                            </th>
                            <th scope="col" className={"col-4"}>
                              Description
                            </th>
                            <th scope="col" className={"col-1"}>
                              Quantity
                            </th>
                            <th scope="col" className={"col-1"}>
                              Unit Price
                            </th>
                            <th scope="col" className={" col-2"}>
                              Total
                            </th>
                            <th scope="col" className={"col-1"}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map((field, index) => (
                            <tr key={field.id} className={""}>
                              <td className={"col-3"}>
                                <Controller
                                  name={`LineItems.${index}.ItemCode`}
                                  className={"form-control"}
                                  control={control}
                                  render={({ field: { onChange } }) => (
                                    <FormGroup>
                                      <MultiSelect
                                        placeholder={"Select Item"}
                                        options={sellableItems.map((c) => ({
                                          value: c.Code,
                                          label: c.Name || c.Description,
                                          unitPrice: c.SalesDetails.UnitPrice
                                        }))}
                                        onChange={(selected) => {
                                          console.log(selected);
                                          setValue(
                                            `LineItems.${index}.UnitAmount`,
                                            selected.unitPrice
                                          );
                                          setValue(
                                            `LineItems.${index}.Description`,
                                            selected.label
                                          );
                                          onChange(selected.value || 0);
                                          trigger("LineItems");
                                        }}
                                      />
                                      <div>&nbsp;</div>
                                    </FormGroup>
                                  )}
                                />
                              </td>
                              <td className={"col-3"}>
                                {
                                  <FormGroup>
                                    <input
                                      className={"form-control"}
                                      {...register(
                                        `LineItems.${index}.Description`,
                                        {
                                          required: "This is required"
                                        }
                                      )}
                                    />
                                    {(errors.LineItems &&
                                      errors.LineItems[index]?.Description && (
                                        <ErrorMessage
                                          errors={errors}
                                          name={`LineItems.${index}.Description`}
                                          render={({ message }) => (
                                            <div className={"text-left"}>
                                              <small className={"text-danger"}>
                                                {message}
                                              </small>
                                            </div>
                                          )}
                                        />
                                      )) || <div>&nbsp;</div>}
                                  </FormGroup>
                                }
                              </td>
                              <td className={"col-1"}>
                                <FormGroup>
                                  <input
                                    className={"form-control"}
                                    {...register(
                                      `LineItems.${index}.Quantity`,
                                      {
                                        valueAsNumber: true
                                      }
                                    )}
                                    type={"number"}
                                    step={"any"}
                                  />
                                  <div>&nbsp;</div>
                                </FormGroup>
                              </td>
                              <td className={"col-1"}>
                                <FormGroup>
                                  <input
                                    className={
                                      "form-control " +
                                      (errors.LineItems &&
                                      errors.LineItems[index]?.UnitAmount
                                        ? "is-invalid"
                                        : "")
                                    }
                                    {...register(
                                      `LineItems.${index}.UnitAmount`,
                                      {
                                        valueAsNumber: true,
                                        required: "This is required"
                                      }
                                    )}
                                    type={"number"}
                                    step={"any"}
                                  />
                                  {(errors.LineItems &&
                                    errors.LineItems[index]?.UnitAmount && (
                                      <ErrorMessage
                                        errors={errors}
                                        name={`LineItems.${index}.UnitAmount`}
                                        render={({ message }) => (
                                          <div className={"text-left"}>
                                            <small className={"text-danger"}>
                                              {message}
                                            </small>
                                          </div>
                                        )}
                                      />
                                    )) || <div>&nbsp;</div>}
                                </FormGroup>
                              </td>
                              <td className={"text-right col-2"}>
                                <FormGroup>
                                  <input
                                    className={
                                      "form-control border-0 text-right"
                                    }
                                    disabled
                                    value={formatAsMoney(
                                      LineItems[index].Quantity *
                                        LineItems[index].UnitAmount || 0
                                    )}
                                  />
                                  <div>&nbsp;</div>
                                </FormGroup>
                              </td>
                              <td className={" col-1 text-center"}>
                                <span
                                  className={
                                    "fa fa-trash delete-invoice-item grow-on-hover p-2 text-lg"
                                  }
                                  role={"button"}
                                  onClick={() => remove(index)}
                                />
                                <div>&nbsp;</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={"text-center mt-4"}>
                    <span
                      className={"px-3 text"}
                      role={"button"}
                      onClick={() =>
                        append({
                          id: uniqueId()
                        })
                      }
                    >
                      <i className={"fa fa-plus-circle"} />
                      &nbsp;Add new item
                    </span>
                    <span
                      className={"p-3"}
                      role={"button"}
                      id={"import-from-timesheets"}
                    >
                      <i className={"fa fa-clock"} />
                      &nbsp;Import from timesheets
                    </span>
                    <UncontrolledPopover
                      placement="bottom"
                      target="import-from-timesheets"
                      className={"unbounded-width"}
                      style={{ minWidth: 200 }}
                    >
                      {!importingFromToggl && (
                        <PopoverBody className={"p-0"}>
                          <div
                            className={"timesheet-option px-4"}
                            onClick={() => setImportingFromToggl(true)}
                          >
                            <img src={togglIcon} width={"20px"} />
                            &nbsp; Toggl
                          </div>
                        </PopoverBody>
                      )}
                      <Modal
                        placement="bottom"
                        isOpen={importingFromToggl}
                        toggle={() =>
                          setImportingFromToggl(!importingFromToggl)
                        }
                        style={{ minWidth: "90vw" }}
                        fade={false}
                      >
                        <ModalHeader>Import from Toggl</ModalHeader>
                        <ModalBody className={"p-0"}>
                          <div className={"timesheet-option px-4"}>
                            <ToggleTimeSheetEntries />
                          </div>
                        </ModalBody>
                      </Modal>
                    </UncontrolledPopover>
                  </div>
                </Container>
                <div>
                  <Button color={"primary float-right"}>Create invoice</Button>
                </div>
              </Form>
            </>
          )}
          {isXeroAuthenticated === null && (
            <>
              <div className={"d-flex justify-content-center py-3"}>
                <img
                  src={xeroIcon}
                  width={100}
                  style={{ animation: "beat 0.5s infinite alternate" }}
                />
              </div>
              <div className={"d-flex justify-content-center"}>
                <p className={"text"}> Getting things ready...</p>
              </div>
            </>
          )}
          {isXeroAuthenticated === false && (
            <>
              <div className={"d-flex justify-content-center py-5"}>
                {/*<img src={xeroIcon} width={150} />*/}
                <i
                  className={"fa fa-8x fa-exclamation-circle text-info beat"}
                />
              </div>
              <div className={"d-flex align-items-center flex-column"}>
                <p className={"text"}>
                  &nbsp;It looks like you have not linked a Xero account
                </p>
                <Button
                  color={"primary"}
                  onClick={() => {
                    setOAuthing(!oAuthing);
                  }}
                >
                  Link it now
                </Button>
              </div>
            </>
          )}
        </CardBody>
      </Card>
      <Modal
        className="modal-dialog-centered"
        isOpen={oAuthing}
        toggle={toggleModal}
        modalTransition={{ timeout: 10 }}
      >
        <ModalBody>
          <XeroOAuth done={authSuccessful} cancel={() => toggleModal()} />
        </ModalBody>
      </Modal>
    </Container>
  );

  function authSuccessful() {
    toggleModal();
    setIsXeroAuthenticated(true);
  }

  function toggleModal() {
    setOAuthing(!oAuthing);
  }

  function getCustomerDisplayValue(customer) {
    let displayValue;
    const { EmailAddress, Name, FirstName, LastName } = customer;
    if (Name || FirstName || LastName) {
      displayValue = Name || `${FirstName} ${LastName}`;
      if (EmailAddress) {
        displayValue += ` (${EmailAddress})`;
      }
    } else {
      displayValue = EmailAddress;
    }

    return displayValue;
  }
};
export default NewXeroInvoice;

import axios from "axios";
import APIError from "./api-error";

let authToken = "";

export async function makeCall(path, data, options = { method: "get" }) {
  const { method, params } = options;
  try {
    const response = await axios({
      url: process.env.REACT_APP_API_BASE + path,
      method,
      data,
      params,
      headers: {
        authorization: "Bearer " + authToken
      }
    });

    return response.data;
  } catch (e) {
    throw new APIError(e.response.data?.message, e);
  }
}

export function setAuthToken(authTokenToSet) {
  authToken = authTokenToSet;
}

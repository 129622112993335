import { Alert, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useQuery } from "react-query";
import api from "../api";
import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";

const HomePage = (props) => {
  const { search } = useLocation();
  const searchObject = Object.fromEntries(new URLSearchParams(search));
  const { apiReady } = useContext(AuthContext);

  const { data: xeroInvoice } = useQuery(
    "xeroInvoice",
    async () => {
      return await api.xero.getInvoice(searchObject.createdXeroInvoice);
    },
    {
      enabled: searchObject.createdXeroInvoice && apiReady
    }
  );
  return (
    <Container>
      {searchObject.createdXeroInvoice && xeroInvoice && (
        <Alert color={"info"}>
          New invoice for <strong>{xeroInvoice.Contact.Name}</strong> created!{" "}
          <a target={"_blank"} href={xeroInvoice.ManageUrl}>
            Open in Xero &nbsp;
            <i className={"fa fa-external-link-alt"} />.
          </a>{" "}
        </Alert>
      )}
      <Link to={"/invoices/new"}>Create new invoice</Link>
    </Container>
  );
};

export default HomePage;

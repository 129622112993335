import { Badge, Col, Row } from "reactstrap";
import React from "react";
import Money from "../Money";

const TogglTimesheetSummaryTable = (props) => {
  const { summary = {}, scrollable = true } = props;
  const {
    data = [],
    total_currencies: totalCurrencies = [],
    total_grand: totalTime
  } = summary;
  const defaultCurrencyTotal = totalCurrencies[0];

  return (
    <div className={"timesheet-entries-table"}>
      <Row>
        <Col>Project</Col>
        <Col>Time</Col>
        <Col>Amount</Col>
      </Row>
      <div className={"entries " + (scrollable ? "scrollable" : "")}>
        <div className="align-items-start">
          {data.map((summary) => {
            const { title, total_currencies, time, id } = summary;
            const { project, client, hex_color } = title;
            const [{ currency, amount }] = total_currencies;
            return (
              <Row key={id}>
                <Col>
                  {project ? (
                    <span style={{ color: hex_color }}> ● {project} </span>
                  ) : (
                    <span style={{ color: hex_color }}> ● No project </span>
                  )}
                  <Badge className="text-muted" pill>
                    {client}
                  </Badge>
                </Col>
                <Col>
                  {Math.floor(time / 1000 / 60 / 60)}:
                  {(Math.floor(time / 1000 / 60) % 60)
                    .toString()
                    .padStart(2, "0")}
                  :
                  {(Math.floor((time / 1000) % 60) % 60)
                    .toString()
                    .padStart(2, "0")}
                </Col>
                <Col>
                  <Money currency={currency} amount={amount} />
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
      <Row>
        <Col />
        <Col>
          {totalTime ? (
            <strong className={"font-size-lg"}>
              {Math.floor(totalTime / 1000 / 60 / 60)}:
              {(Math.floor(totalTime / 1000 / 60) % 60)
                .toString()
                .padStart(2, "0")}
              :
              {(Math.floor((totalTime / 1000) % 60) % 60)
                .toString()
                .padStart(2, "0")}
            </strong>
          ) : (
            "0:00"
          )}
        </Col>
        <Col>
          <strong>
            {defaultCurrencyTotal ? (
              <Money
                amount={defaultCurrencyTotal.amount}
                currency={defaultCurrencyTotal.currency}
              />
            ) : (
              0
            )}
          </strong>
        </Col>
      </Row>
    </div>
  );
};

export default TogglTimesheetSummaryTable;

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  PopoverBody,
  Row,
  UncontrolledPopover
} from "reactstrap";
import { useQuery } from "react-query";
import api from "../api";
import React, { useContext, useState } from "react";
import MultiSelect from "../components/MultiSelect";
import AuthContext from "../contexts/AuthContext";
import InvoiceItem from "../components/InvoiceItem";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router";

const NewInvoice = (props) => {
  const { apiReady } = useContext(AuthContext);
  const history = useHistory();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [uiInvoiceItems] = useState([
    {
      id: 1
    }
  ]);
  const [invoiceItems, setInvoiceItems] = useState([{}]);

  const { data: customers = [] } = useQuery(
    "waveCustomers",
    async () => {
      const customers = await api.getWaveCustomers();
      setSelectedCustomer({
        value: customers[0].id,
        label: getCustomerDisplayValue(customers[0])
      });

      return customers;
    },
    {
      enabled: apiReady
    }
  );

  const { data: products = [] } = useQuery(
    "waveProducts",
    api.getWaveProducts,
    {
      enabled: apiReady
    }
  );

  const { data: userApps = [] } = useQuery("userApps", api.getUserApps, {
    enabled: apiReady
  });
  const { data: waveAuthUrl = {} } = useQuery(
    "waveAuthUrl",
    api.getWaveAuthUrl,
    {
      enabled: !!(
        apiReady &&
        userApps.length &&
        !userApps.find((a) => a.name === "wave").isConnected
      ),
      staleTime: 300000,
      refetchOnMount: true
    }
  );

  return (
    <Container>
      <h3>New invoice</h3>
      <Card>
        <CardBody>
          <Form>
            <FormGroup>
              <label htmlFor="customer">
                {" "}
                <small>Customer</small>
              </label>
              <MultiSelect
                name={"customer"}
                value={
                  //hardcode for now
                  customers
                    ? customers.slice(0, 1).map((c) => ({
                        value: c.id,
                        label: getCustomerDisplayValue(c)
                      }))
                    : {}
                }
                options={customers.map((c) => ({
                  value: c.id,
                  label: getCustomerDisplayValue(c)
                }))}
                onChange={(c) => {
                  setSelectedCustomer(c);
                }}
              />
            </FormGroup>
            <FormGroup>
              <h4>Items</h4>
              {/*<Alert color={"secondary"}>*/}
              {/*  <strong>*/}
              {/*    Hint: Click on the purple logo to retrieve the number of hours*/}
              {/*    directly from Toggl timesheets*/}
              {/*  </strong>*/}
              {/*  .*/}
              {/*</Alert>*/}
              {products.length > 0
                ? uiInvoiceItems.map((item, index) => (
                    <InvoiceItem
                      products={products}
                      // key={uniqueId()}
                      onChange={(itemData) => {
                        updateInvoiceItem(index, itemData);
                      }}
                    />
                  ))
                : null}
            </FormGroup>
          </Form>
          <UncontrolledPopover
            placement="top"
            target="generate-invoice-options"
            className="popover-default unbounded-width"
            trigger="legacy"
            hideArrow={true}
            fade={false}
          >
            <PopoverBody>
              {userApps
                .filter((a) => a.type === "invoicing")
                .map((app) => (
                  <Container key={app.name}>
                    <Row
                      className={`generate-invoice-option py-3 ${
                        !app.isConnected ? "disabled" : ""
                      }`}
                      onClick={() => app.isConnected && createInvoice()}
                    >
                      <Col>
                        {
                          <div
                            className={
                              "d-flex align-items-center text-default option-name"
                            }
                          >
                            <img src={app.icon} alt={""} />
                            &nbsp;&nbsp;{app.displayName}
                          </div>
                        }
                      </Col>
                      <div className={"d-flex justify-content-end"}>
                        {!app.isConnected && app.name === "wave" && (
                          <a
                            href={waveAuthUrl.authorizeUrl}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <Button caret color={"primary"} size={"sm"}>
                              Connect account
                            </Button>
                          </a>
                        )}
                      </div>
                    </Row>
                  </Container>
                ))}
            </PopoverBody>
          </UncontrolledPopover>
          <div>
            <Button
              color={"primary"}
              className={"float-right"}
              id={"generate-invoice-options"}
            >
              Create invoice
              <span className="caret" />
            </Button>
          </div>
        </CardBody>
      </Card>
    </Container>
  );

  function getCustomerDisplayValue(customer) {
    let displayValue;
    const { email, firstName, lastName } = customer;
    if (firstName || lastName) {
      displayValue = `${firstName} ${lastName}`;
      if (email) {
        displayValue += ` (${email})`;
      }
    } else {
      displayValue = email;
    }

    return displayValue;
  }

  function updateInvoiceItem(index, itemData) {
    const updatedItems = cloneDeep(invoiceItems);
    updatedItems[index].data = itemData;
    setInvoiceItems(updatedItems);
  }

  async function createInvoice() {
    const invoiceData = {
      customerId: selectedCustomer.value,
      status: "DRAFT",
      title: "TTGP Test Invoice",
      subhead: "",
      invoiceNumber: "",
      poNumber: "",
      invoiceDate: new Date().toISOString(),
      dueDate: new Date().toISOString(),
      items: invoiceItems.map((item) => ({
        productId: item.data.selectedProduct.value,
        description: item.data.selectedProduct.label,
        quantity: item.data.itemQuantity,
        unitPrice: item.data.unitPrice
      }))
    };

    const response = await api.createWaveInvoice(invoiceData);
    window.open(response.viewUrl, "_blank");
    history.push("/");
  }
};
export default NewInvoice;

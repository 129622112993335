import "./assets/scss/app/App.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import RegisterPage from "./pages/Register";
import AuthContextProvider from "./components/context-providers/AuthContextProvider";
import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";
import Timesheets from "./pages/Timesheets";
import NewXeroInvoice from "./pages/NewXeroInvoice";
import NewInvoice from "./pages/NewInvoice";
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <div className={"mt-6"}>
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => <HomePage {...props} />}
              />
              <Route
                path="/register"
                exact
                render={(props) => <RegisterPage {...props} />}
              />
              <Route
                path="/login"
                exact
                render={(props) => <LoginPage {...props} />}
              />
              <Route
                path="/timesheets"
                exact
                render={(props) => <Timesheets {...props} />}
              />
              <Route
                path="/invoices/new"
                exact
                render={(props) => <NewXeroInvoice {...props} />}
              />
              <Route
                path="/invoices/new/wave"
                exact
                render={(props) => <NewInvoice {...props} />}
              />
            </Switch>
            <ReactQueryDevtools initialIsOpen={false} />
          </div>
        </AuthContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;

export default class APIError extends Error {
  constructor(message, error) {
    super();
    const errorMessage = `${message || error.message}`;
    const extendedErrorMessage = `API error:    ${errorMessage} : ${JSON.stringify(
      error.response.data
    )}`;
    return { ...error, message: errorMessage, extendedErrorMessage };
  }
}

const Money = ({ amount, currency }) => {
  return (
    <>
      <span className={"text-muted"}>{currency}</span>
      &nbsp;
      <span>{formatAsMoney(amount)}</span>
    </>
  );
};

export function formatAsMoney(amount) {
  return amount || amount === 0
    ? new Intl.NumberFormat({ minimumSignificantDigits: 2 }).format(amount)
    : "";
}
export default Money;
